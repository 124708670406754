import createPlugin from '@@/create-plugin';
import { createSubBook } from '@/lib/sub-book';
import { getPluginConfig } from '@@/use-config';

export default createPlugin({
    components: {
        'editor:catalog:menu:item': () => import('./components/editor/menu-item'),
        'editor:catalog:items': () => import('./components/editor/items'),
        'editor:catalog:part:title': () => import('./components/editor/part-title'),
        'editor:catalog:part': () => import('./components/editor/part'),
        'page:asset': [() => import('./components/reader/asset'), { priority: 99 }],
        'page:menu:item': () => import('./components/reader/menu'),
        'activate:editor': () => import('./components/editor/activate'),
        'activate:reader': () => import('./components/reader/activate'),
    },
    async register(context, book) {

        const config = getPluginConfig(book.config);

        const subBook = createSubBook(config.getValue('host', location.origin));

        if (context === 'reader') {
            await Promise.all(book.summary.parts.map(async (part) => {
                const id = part.getMetadata('reference');
                if (id) {
                    const articles = await subBook.getArticles(id);
                    if (articles) {
                        part.articles = articles;
                    }
                }
            }));

            book.summary.indexLevels();
        }
    }
});
